import { template as template_cf1b2ac673754a5ba7ac9ca56dc48173 } from "@ember/template-compiler";
const WelcomeHeader = template_cf1b2ac673754a5ba7ac9ca56dc48173(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
