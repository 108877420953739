import { template as template_f5014f323dba4444a7dc30ea31434b57 } from "@ember/template-compiler";
const FKText = template_f5014f323dba4444a7dc30ea31434b57(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
