import { template as template_86767dd4483b4d8a8b3d44c336db86d3 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_86767dd4483b4d8a8b3d44c336db86d3(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
